import React from 'react';
import "./index.scss";
import animateModule from "../../modules/animateModule";
import { Routes, Route, useNavigate } from "react-router-dom";

import { bosnian } from '../../languages/bosnian';
import { english } from '../../languages/english';

export default function Marketing(props) {
    const [lang, setLang] = React.useState(bosnian);

    const mainNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".route__landingPage"));
    };

    React.useEffect(() => {
        if (props.language.value === 'BA') {
            setLang(bosnian);
        }
        else {
            setLang(english);
        }
    }, [props.language.value]);

    return (
        <div className='route__marketing'>
            <div className='route__marketing__top'>
                <h1>COD Lead Generation</h1>
                <div onClick={() => animateNavigate('/')} className='route__webdevelopment__right__top__button'><p>{lang.GoBack}</p></div>
            </div>
            <p>{lang.MarketingDesc}</p>
            <br></br>
            <p>{lang.MarketingTypes}</p>
            <br></br>
            <p>{lang.Conf}</p>
            <div className='route__marketing__gallery'>
                <div className='route__marketing__gallery__image'>
                    <h3>Affiliate World Bangkok 2022</h3>
                    <img src='images/thailand.jpg' alt='' />
                </div>
                <div className='route__marketing__gallery__image'>
                    <h3>Affiliate World Dubai 2023</h3>
                    <img src='images/dubai.jpg' alt='' />
                </div>
                <div className='route__marketing__gallery__image'>
                    <h3>Affiliate World Barcelona 2022</h3>
                    <img src='images/barcelona.jpeg' alt='' />
                </div>
            </div>
        </div>
    )
}
