import React from 'react';
import "./index.scss";
import SimpleDropdown from "../SimpleDropdown";
import { bosnian } from '../../languages/bosnian';
import { english } from '../../languages/english';
import { useSearchParams } from "react-router-dom";

export default function Header(props) {
  const [lang, setLang] = React.useState(bosnian);
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if(props.language.value === 'BA'){
      setLang(bosnian);
    }
    else{
      setLang(english);
    }
  }, [props.language.value]);

  return (
    <div className='component__header'>
      <div className='component__header__left'>
        <img className='component__header__left__logo' src='images/logo.png' alt='' />
        <h1 className='component__header__left__title'>DŽENAN IBRIĆ</h1>
      </div>
      <div className='component__header__right'>
        <SimpleDropdown
          heading={searchParams.get('lang') ? searchParams.get('lang')==='BA' ? 'Bosnian' : 'English' : 'English'}
          data={[{ name: lang.Bosnian, value: 'BA' }, { name: lang.English, value: 'EN' }]}
          headStyle={{ background: '#2B2D42', width: 'fit-content' }}
          textStyle={{ color: '#8D99AE', fontWeight: '600' }}
          bodyStyle={{ width: '100%', background: '#8D99AE' }}
          mainStyle={{ width: 'fit-content' }}
          itemStyle={{ fontWeight: '600' }}
          setSelected={props.setLanguage}
          xCallback={props.setLanguage}
          imgSrc={'images/language.png'}
        />
        <img onClick={() => { window.location.href = 'mailto:dzenanibric2002@gmail.com' }} className='component__header__right__icon' src='images/gmail.png' alt='' />
        <img onClick={() => { window.location.href = 'https://www.linkedin.com/in/d%C5%BEenan-ibri%C4%87-33a204252/' }} className='component__header__right__icon' src='images/linkedin.png' alt='' />
        <img onClick={() => { window.location.href = 'https://github.com/dzenanibric' }} className='component__header__right__icon' src='images/github.png' alt='' />
      </div>
    </div>
  )
}
