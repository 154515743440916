const english = {
    Bosnian: 'Bosnian',
    English: 'English',
    ChangeLanguage: 'Change language',
    Welcome: 'WELCOME!',
    AboutMe: 'I am Dženan Ibrić, I was born in 2002. I am from Bosnia and Herzegovina. I stepped into this world relatively young, at only 13 years old, already the next year I knew what I wanted, which is programming. In the beginning, I changed programming languages constantly, but in the end, about 4 years ago, I decided only for WEB, more precisely JavaScript. Marketing came relatively recently, 2 years ago, by chance, but I can say that I have become an expert in it as well. So, today I can offer you the services of Web Development and Online Marketing.',
    CTA: 'Below, click to choose what exactly you need me for',
    DevExp: 'Experience of more than 4 years',
    LearnMore: 'Find out more',
    MarkExp: 'Experience of more than 2 years',
    Contact: 'CONTACT',
    Menu: 'MENU',
    Languages: 'MY LANGUAGES',
    Links: 'LINKS',
    Course: 'Attend my course',
    MyBlog: 'My blog',
    Projects: 'Projects',
    Skills: 'Skills',
    ExpWebDev: 'Experience of more than 4 years, of which 3 years freelance and 1 year and 4 months in the company. I am full stack, but it is not a problem for me to do only frontend or only backend.',
    GoBack: 'Go back',
    RocketAdDesc: 'CRM - collection of clients from various other websites via API, client statistics, traffic, profit, activity by day, month, year. Integration with publishers, statistics and tracking of publishers. Complete management of the company`s finances. Tracker - monitoring visits on all sites, per site, tracking conversions, numerically and in percentages. Post - Integration with the post, automatic creation and printing of labels, automatic sending of orders to the post, feedback from the post that is applied to the specific client and to the finances. Post statistics. User hierarchy. The pictures show how much I can show.',
    ReadMore: 'Find out more',
    RocketAdLtdDesc: 'The website is responsive, pixel perfect, with some animations, a contact form and the most basic things.',
    EduDesc: 'Responsive Multipage Website, pixel perfect, with a couple of elegant animations, with various interactive content, a contact form, and a beautiful modern design.',
    CreatedUsing: 'Created using:',
    ShopDesc: 'Advanced web shop, with a handful of options from the admin panel, integrated with another CRM that I`m not allowed to show and integrated with the aforementioned Invoicing. Automatically adjusted to the visitor`s country using their location. I made this project in the previous company Obscale d.o.o.',
    InvoicingDesc: 'Web application for invoicing, finance, billing, automatic invoicing after sales on the web shop, integration with CRM and integration with the web shop. Automatic change of invoice status after it is paid or similar, and much more.',
    Note: 'I worked on this project in the previous company Obscale d.o.o. That`s why I`m not allowed to show any pictures.',
    CupidDesc: 'Dating platform, live streams, chat, buy texting credits, gifts, collect free credits, admin panel to manage the whole platform and much more. I worked on this project in the previous company Obscale d.o.o.',
    MarketingDesc: 'I have been doing lead generation form of marketing for COD type for two years now. COD is translated into English as cash on delivery, so I am able to bring potential clients for your business who will leave their name and phone number and you will make a sale during the call.',
    MarketingTypes:'The forms of cooperation are: you pay per confirmed sale or you pay for each lead/contact',
    Conf: 'By the way, I have been to 3 big marketing conferences:',
    TrackingDesc: 'Tracking platform - monitoring of entrances/clicks to a site, percentage of conversion of entrances/clicks into leads, tracking of leads, calculation of price per click/entry (CPC), price of lead (CPL), calculation of profit and spending, sales monitoring and calculation of approve rate, statistics in total and for today. All of this sorted by campaigns and domains. Tracking takes place through a pixel that is generated uniquely for each campaign, and the API token for each individual domain is responsible for security and ensures accuracy and maximum precision of the data. There is also the possibility of multiple users and their hierarchy. A report that, according to the desired parameters, shows which campaign achieved the best result.'
}

export {english};