import React from 'react';
import "./index.scss";
import animateModule from "../../modules/animateModule";
import { Routes, Route, useNavigate } from "react-router-dom";

import { bosnian } from '../../languages/bosnian';
import { english } from '../../languages/english';

export default function LandingPage(props) {
    const [lang, setLang] = React.useState(bosnian);

    const mainNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".route__landingPage"));
    };

    React.useEffect(() => {
        if (props.language.value === 'BA') {
            setLang(bosnian);
        }
        else {
            setLang(english);
        }
    }, [props.language.value]);

    return (
        <div className='route__landingPage'>
            <div className='route__landingPage__left'>
                <h1 className='route__landingPage__left__welcome'>{lang.Welcome}</h1>
                <p className='route__landingPage__left__text'>
                    {lang.AboutMe}
                </p>
                <div className='route__landingPage__left__tablet'>
                    <div className='route__landingPage__left__tablet__left'>
                        <h1 className='route__landingPage__left__tablet__left__welcome'>{lang.Welcome}</h1>
                        <p className='route__landingPage__left__tablet__left__text'>
                            {lang.AboutMe}
                        </p>
                    </div>
                    <img src='images/ja.jpg' alt='' />
                </div>
                <div className='route__landingPage__left__wrapper'>
                    <h2 className='route__landingPage__left__wrapper__cta'>{lang.CTA}</h2>
                    <div className='route__landingPage__left__wrapper__options'>
                        <div onClick={(e) => animateNavigate('/web-development')} className='route__landingPage__left__wrapper__options__button'>
                            <img src='images/dev.png' alt='' />
                            <h2>WEB DEVELOPMENT</h2>
                            <p>{lang.DevExp}</p>
                            <span>
                                <p>{lang.LearnMore}</p>
                                <img src='images/arrow.png' alt='' />
                            </span>
                        </div>
                        <div onClick={(e) => animateNavigate('/marketing')} className='route__landingPage__left__wrapper__options__button'>
                            <img src='images/marketing.png' alt='' />
                            <h2>ONLINE MARKETING</h2>
                            <p>{lang.MarkExp}</p>
                            <span>
                                <p>{lang.LearnMore}</p>
                                <img src='images/arrow.png' alt='' />
                            </span>
                        </div>
                    </div>
                </div>
                <img className='route__landingPage__left__mobilePic' src='images/ja.jpg' alt='' />
            </div>
            <div className='route__landingPage__right'>
                <img src='images/ja.jpg' alt='' />
            </div>
        </div>
    )
}
