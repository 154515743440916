import React from "react";
import "./index.scss";

import PropTypes from "prop-types";

const SimpleDropdown = (props) => {
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(null);

    React.useEffect(() => {
        let handler = () => setOpen(false);

        document.body.addEventListener("click", handler);

        return () => document.body.removeEventListener("click", handler);
    }, [open]);

    return <div style={props.mainStyle} className={props.className ? `component__simpleDropdown ${props.className}` : 'component__simpleDropdown'}>
        <div className="component__simpleDropdown__closeBtn" style={{ background: 'url(/images/head_close.svg)' }} onClick={() => {
            setOpen(false); props.xCallback({
                clearSelected: () => setSelected(null)
            })
        }}></div>
        <div style={props.headStyle} onClick={(e) => { e.stopPropagation(); setOpen(o => !o) }} className="component__simpleDropdown__head">
            <p className="component__simpleDropdown__head__text" style={props.textStyle}>{selected ? selected : props.heading}</p>
            {props.imgSrc && <img className="component__simpleDropdown__head__img" src={props.imgSrc} alt="" />}
        </div>
        <div style={props.bodyStyle} className={open ? 'component__simpleDropdown__body component__simpleDropdown__body__active' : 'component__simpleDropdown__body'}>
            {
                props.data?.map(item => {
                    return <p
                        style={props.itemStyle}
                        onClick={() => { props.setSelected(item); setSelected(item.name) }}
                        className="component__simpleDropdown__body__item"
                    >
                        {item.name}
                    </p>
                })
            }
        </div>
    </div>
}

SimpleDropdown.propTypes = {
    heading: PropTypes.string,
    setSelected: PropTypes.func,
    xCallback: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
    })),
    headStyle: PropTypes.any,
    textStyle: PropTypes.any,
    bodyStyle: PropTypes.any,
    mainStyle: PropTypes.any,
    itemStyle: PropTypes.any,
    imgSrc: PropTypes.any
}

export default SimpleDropdown;