import React from 'react';
import "./index.scss";
import animateModule from "../../modules/animateModule";
import { Routes, Route, useNavigate } from "react-router-dom";
import { bosnian } from '../../languages/bosnian';
import { english } from '../../languages/english';

export default function Footer(props) {
    const [lang, setLang] = React.useState(bosnian);

    const mainNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".route__landingPage"));
    };

    React.useEffect(() => {
        if (props.language.value === 'BA') {
            setLang(bosnian);
        }
        else {
            setLang(english);
        }
    }, [props.language.value]);

    return (
        <div className='component__footer'>
            <div className='component__footer__contact'>
                <h4>{lang.Contact}</h4>
                <div className='component__footer__contact__mail'>
                    <img onClick={() => { window.location.href = 'mailto:dzenanibric2002@gmail.com' }} className='component__footer__icon' src='images/gmail.png' alt='' />
                    <p>dzenanibric2002@gmail.com</p>
                </div>
                <div className='component__footer__contact__phone'>
                    <img className='component__footer__icon' src='images/viber.png' alt='' />
                    <img className='component__footer__icon' src='images/whatsapp.png' alt='' />
                    <p>+387603363811</p>
                </div>
            </div>
            <div className='component__footer__menu'>
                <h4>{lang.Menu}</h4>
                <p onClick={(e) => animateNavigate('/web-development')}>Web development</p>
                <p onClick={(e) => animateNavigate('/marketing')}>Online marketing</p>
                <p onClick={(e) => animateNavigate('/course')}>{lang.Course}</p>
            </div>
            <div className='component__footer__languages'>
                <h4>{lang.Languages}</h4>
                <p>{lang.Bosnian}</p>
                <p>{lang.English}</p>
            </div>
            <div className='component__footer__links'>
                <h4>{lang.Links}</h4>
                <div className='component__footer__links__icons'>
                    <img onClick={() => { window.location.href = 'https://www.linkedin.com/in/d%C5%BEenan-ibri%C4%87-33a204252/' }} className='component__footer__icon' src='images/linkedin.png' alt='' />
                    <img onClick={() => { window.location.href = 'https://github.com/dzenanibric' }} className='component__footer__icon' src='images/github.png' alt='' />
                </div>
                <p onClick={(e) => animateNavigate('/blog')}>{lang.MyBlog}</p>
            </div>
        </div>
    )
}
