import React from 'react';
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";

import LandingPage from "./routes/LandingPage";
import WebDevelopment from './routes/WebDevelopment';
import Course from './routes/Course';
import Marketing from './routes/Marketing';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = React.useState({name: 'English', value: searchParams.get('lang') ? searchParams.get('lang') : 'EN'});

  return (
    <div className="route__contentWrapper">
      <div className="route__contentWrapper__container">
        <Header setLanguage={setLanguage} language={language} />
        <Routes>
          <Route path="/" element={<LandingPage language={language} />} />
          <Route path="/web-development" element={<WebDevelopment language={language} />} />
          <Route path="/marketing" element={<Marketing language={language} />} />
          <Route path="/course" element={<Course language={language} />} />
          <Route path="/blog" element={<Course language={language} />} />
        </Routes>
        <Footer language={language} />
        <ScrollToTop />
      </div>
    </div>
  );
}

export default App;