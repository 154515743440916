import React from 'react';
import "./index.scss";

export default function Course() {
  return (
    <div>
        <br></br>
        <h2>Coming soon...</h2>
    </div>
  )
}
