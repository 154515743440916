import React from 'react';
import "./index.scss";
import animateModule from "../../modules/animateModule";
import { Routes, Route, useNavigate } from "react-router-dom";
import { animateBox } from "../../modules/componentAnimation";

import { bosnian } from '../../languages/bosnian';
import { english } from '../../languages/english';

export default function WebDevelopment(props) {
    const [lang, setLang] = React.useState(bosnian);

    const mainNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".route__landingPage"));
    };

    React.useEffect(() => {
        if (props.language.value === 'BA') {
            setLang(bosnian);
        }
        else {
            setLang(english);
        }
    }, [props.language.value]);

    return (
        <div className='route__webdevelopment'>
            <div className='route__webdevelopment__left'>
                <h1 className='route__webdevelopment__left__title'>{lang.Projects}</h1>
                <div className='route__webdevelopment__left__projects'>
                    <div
                        onClick={(e) => animateBox(e, <SingleProjectModal
                            name='DI-Tracking'
                            imgs={['images/tracking1.png', 'images/tracking2.png', 'images/tracking3.png', 'images/tracking4.png', 'images/tracking5.png']}
                            description={lang.TrackingDesc}
                            skills={['JavaScript,', 'React.js,', 'Node.js,', 'MySQL,', 'API,', 'SCSS']}
                            lang={lang}
                        />)}
                        className='route__webdevelopment__left__projects__single'
                    >
                        <img src='images/tracking5.png' alt='' />
                        <span>
                            <h3>DI-Tracking</h3>
                            <p>{String(lang.TrackingDesc).substring(0, 180) + "..."}</p>
                            <strong>{lang.ReadMore}</strong>
                        </span>
                    </div>
                    <div
                        onClick={(e) => animateBox(e, <SingleProjectModal
                            name='RocketAd CRM'
                            imgs={['images/rocketadcrm1.png', 'images/rocketadcrm2.png', 'images/rocketadcrm3.png']}
                            description={lang.RocketAdDesc}
                            skills={['JavaScript,', 'React.js,', 'Node.js,', 'MySQL,', 'API,', 'SCSS']}
                            lang={lang}
                        />)}
                        className='route__webdevelopment__left__projects__single'
                    >
                        <img src='images/rocketadcrm2.png' alt='' />
                        <span>
                            <h3>RocketAd CRM</h3>
                            <p>{String(lang.RocketAdDesc).substring(0, 180) + "..."}</p>
                            <strong>{lang.ReadMore}</strong>
                        </span>
                    </div>
                    <div
                        className='route__webdevelopment__left__projects__single'
                        onClick={(e) => animateBox(e, <SingleProjectModal
                            name='RocketAd Ltd'
                            imgs={['images/rocketadltd1.png', 'images/rocketadltd2.png']}
                            description={lang.RocketAdLtdDesc}
                            skills={['JavaScript,', 'HTML,', 'CSS']}
                            link='https://rocketad-ltd.com'
                            lang={lang}
                        />)}
                    >
                        <img src='images/rocketadltd1.png' alt='' />
                        <span>
                            <h3>RocketAd Ltd</h3>
                            <p>{lang.RocketAdLtdDesc}</p>
                            <strong>{lang.ReadMore}</strong>
                        </span>
                    </div>
                    <div
                        className='route__webdevelopment__left__projects__single'
                        onClick={(e) => animateBox(e, <SingleProjectModal
                            name='Invoicing'
                            description={lang.InvoicingDesc}
                            skills={['JavaScript,', 'React.js,', 'Node.js,', 'MySQL,', 'SCSS']}
                            Note={lang.Note}
                            lang={lang}
                        />)}
                    >
                        <img src='images/noimg.png' alt='' />
                        <span>
                            <h3>Invoicing</h3>
                            <p>{String(lang.InvoicingDesc).substring(0, 180) + "..."}</p>
                            <strong>{lang.ReadMore}</strong>
                        </span>
                    </div>
                    <div
                        className='route__webdevelopment__left__projects__single'
                        onClick={(e) => animateBox(e, <SingleProjectModal
                            name='Web shop'
                            imgs={['images/bioplar.png', 'images/bioplar2.png']}
                            description={lang.ShopDesc}
                            skills={['JavaScript,', 'React.js,', 'Node.js,', 'MySQL,', 'SCSS']}
                            link='https://bioplar.com'
                            lang={lang}
                        />)}
                    >
                        <img src='images/bioplar.png' alt='' />
                        <span>
                            <h3>Web shop</h3>
                            <p>{String(lang.ShopDesc).substring(0, 180) + "..."}</p>
                            <strong>{lang.ReadMore}</strong>
                        </span>
                    </div>
                    <div
                        className='route__webdevelopment__left__projects__single'
                        onClick={(e) => animateBox(e, <SingleProjectModal
                            name='Dating platform'
                            imgs={['images/cupid1.png', 'images/cupid2.png']}
                            description={lang.CupidDesc}
                            skills={['JavaScript,', 'React.js,', 'Node.js,', 'MySQL,', 'SCSS']}
                            link='https://cupidcoast.com'
                            lang={lang}
                        />)}
                    >
                        <img src='images/cupid1.png' alt='' />
                        <span>
                            <h3>Dating platform</h3>
                            <p>{String(lang.ShopDesc).substring(0, 180) + "..."}</p>
                            <strong>{lang.ReadMore}</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className='route__webdevelopment__right'>
                <div className='route__webdevelopment__right__top'>
                    <h1 className='route__webdevelopment__right__top__title'>{lang.Skills}</h1>
                    <div onClick={() => animateNavigate('/')} className='route__webdevelopment__right__top__button'><p>{lang.GoBack}</p></div>
                </div>
                <div className='route__webdevelopment__right__skills'>
                    <div className='route__webdevelopment__right__skills__single'>
                        <img src='images/html.png' alt='' />
                        <p>HTML 5</p>
                    </div>
                    <div className='route__webdevelopment__right__skills__single'>
                        <img src='images/sass.png' alt='' />
                        <p>SASS / SCSS (CSS)</p>
                    </div>
                    <div className='route__webdevelopment__right__skills__single'>
                        <img src='images/js.png' alt='' />
                        <p>JavaScript</p>
                    </div>
                    <div className='route__webdevelopment__right__skills__single'>
                        <img src='images/react.png' alt='' />
                        <p>React.js</p>
                    </div>
                    <div className='route__webdevelopment__right__skills__single'>
                        <img src='images/node.png' alt='' />
                        <p>Node.js</p>
                    </div>
                    <div className='route__webdevelopment__right__skills__single'>
                        <img src='images/sql.png' alt='' />
                        <p>SQL</p>
                    </div>

                    <h3 className='route__webdevelopment__right__skills__text'>{lang.ExpWebDev}</h3>

                    <span className='route__webdevelopment__right__skills__contact'>
                        <img src='images/mail.png' alt='' />
                        <p>dzenanibric2002@gmail.com</p>
                    </span>

                    <span className='route__webdevelopment__right__skills__contact'>
                        <img src='images/phone.png' alt='' />
                        <p>+387603363811</p>
                    </span>

                    <div onClick={() => animateNavigate('/course')} className='route__webdevelopment__right__skills__button'>
                        <p>{lang.Course}</p>
                    </div>
                    <div onClick={() => animateNavigate('/blog')} className='route__webdevelopment__right__skills__button'>
                        <p>{lang.MyBlog}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SingleProjectModal = (props) => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div className='singleProjectModal'>
        <div className='singleProjectModal__wrapper'>
            <div className='singleProjectModal__wrapper__head'>
                <h2 className='singleProjectModal__wrapper__head__title'>{props.name}</h2>
                <img onClick={props.onClose} className='singleProjectModal__wrapper__head__close' src='images/yesno__closeIcon.svg' alt='' />
            </div>
            <div className='singleProjectModal__wrapper__gallery'>
                {
                    props.imgs?.map(item => {
                        return <img onClick={(e) => animateBox({ currentTarget: e.target.parentNode.parentNode.parentNode }, <ViewImageModal src={item} />)} src={item} alt='' />
                    })
                }
            </div>
            <p className='singleProjectModal__wrapper__description'>{props.description}</p>
            {props.Note && <p className='singleProjectModal__wrapper__note'>{props.Note}</p>}
            <a className='singleProjectModal__wrapper__link' href={props.link}>{props.link ? 'Link: ' + props.link : ''}</a>
            <div className='singleProjectModal__wrapper__skills'>
                <p>{props.lang.CreatedUsing}</p>
                {
                    props.skills?.map(item => {
                        return <p>{item}</p>
                    })
                }
            </div>
        </div>
    </div>
}

const ViewImageModal = (props) => {
    return <div className='viewImageModal'>
        <div className='viewImageModal__wrapper'>
            <img onClick={props.onClose} className='viewImageModal__wrapper__close' src='images/yesno__closeIcon.svg' alt='' />
            <img className='viewImageModal__wrapper__img' src={props.src} alt='' />
        </div>
    </div>
}