const bosnian = {
    Bosnian: 'Bosnian',
    English: 'English',
    ChangeLanguage: 'Promijeni jezik',
    Welcome: 'DOBRODOŠLI!',
    AboutMe: 'Ja sam Dženan Ibrić, rođen sam 2002. godine. Iz Bosne i Hercegovine sam. U ovaj svijet sam zakoračio relativno mlad, sa samo 13 godina, već sljedeće sam znao šta želim, a to je programiranje. U početku sam mijenjao programske jezike stalno, ali na kraju prije nekih 4 godine odlučio sam se samo za WEB odnosno JavaScript. Marketing je došao relativno skoro, prije 2 godine, igrom slučajnosti, ali mogu reći da sam i u njemu postao ekspert. Tako da, ja Vam danas mogu ponuditi usluge Web Development-a i Online Marketinga.',
    CTA: 'Ispod, klikom odaberite za šta sam vam tačno potreban',
    DevExp: 'Iskustvo više od 4 godine',
    LearnMore: 'Saznaj više',
    MarkExp: 'Iskustvo više od 2 godine',
    Contact: 'KONTAKT',
    Menu: 'NAVIGACIJA',
    Languages: 'MOJI JEZICI',
    Links: 'LINKOVI',
    Course: 'Pohađaj moj kurs',
    MyBlog: 'Moj blog',
    Projects: 'Projekti',
    Skills: 'Vještine',
    ExpWebDev: 'Iskustvo više od 4 godine od čega 3 godine freelance i 1 godina i 4 mjeseca u firmi. Full stack sam, ali nije mi problem raditi samo frontend ili samo backend.',
    GoBack: 'Vrati se nazad',
    RocketAdDesc: 'CRM - prikupljanje klijenata sa raznih drugih web stranica putem API-ja, statistike klijenata, prometa, profita, aktivnosti po danu, mjesecu, godini. Integracija sa publisherima, statistika i praćenje publishera. Kompletno upravljanje finansijama kompanije. Tracker - praćenje posjeta na svim stranicama, po stranici, praćenje konverzija, brojčano i u procentima. Pošta - Integracija sa poštom, automatsko kreiranje i štampanje etiketa, automatsko slanje naloga pošti, povratne informacije od pošte koje se primenjuju na konkretnog klijenta i na finansije. Statistika pošte. Hijerarhija korisnika. Slike pokazuju koliko smijem da pokažem.',
    ReadMore: 'Klikni za više',
    RocketAdLtdDesc: 'Web stranica responzivna, pixel perfect, sa nešto animacija, kontakt formom i najosnovnijim stvarima.',
    EduDesc: 'Responzivna Multipage Web stranica, pixel perfect, sa par elegantnih animacija, sa raznim interaktivnim sadržajem, kontakt formom, i prelijepim modernim dizajnom.',
    CreatedUsing: 'Korišteno: ',
    ShopDesc: 'Napredni web shop, sa pregršt opcija s admin panela, integriran sa drugim CRM-om kojeg ne smijem pokazati i gore pomentuim Invoicing-om. Automatski se prilagođava zemlji posjetitelja koristeći njihovu lokaciju. Ovaj projekt sam radio u prethodnoj firmi Obscale d.o.o.',
    InvoicingDesc: 'Web aplikacija za fakturisanje, finansije, naplatu, automatsko izdavanje računa nakon prodaje na web shop-u, integracija sa CRM-om i integracija sa web shopom. Automatska promjena statusa fakture nakon što je plaćena ili slično, i još mnogo toga.',
    Note: 'Ovaj projekt sam radio u prethodnoj firmi Obscale d.o.o. Zbog toga ne smijem pokazati nikakve slike.',
    CupidDesc: 'Platforma za upoznavanje, live streamovi, chat, kupovina kredita za slanje poruka, pokloni, prikupljanje besplatnih kredita, admin panel za upravljanje cijelom platformom i još mnogo toga. Ovaj projekt sam radio u prethodnoj firmi Obscale d.o.o.',
    MarketingDesc: 'Već dvije godine radim lead generation oblik marketinga za COD tip. COD je u prevodu na engleski cash on delivery, dakle sposoban sam donositi potencijalne klijente za vaš biznis koji će ostaviti svoje ime i broj telefona a vi ćete tokom poziva napraviti prodaju. ',
    MarketingTypes: 'Oblici sudradnje su: plaćate po potvrđenoj prodaji ili plaćate za svaki lead/kontakt',
    Conf: 'Inače, bio sam na 3 velike marketing konferencije:',
    TrackingDesc: 'Tracking platforma- praćenje ulaza/klikova na neki sajt, procenat konverzije ulaza/klikova u lead-ove, praćenje lead-ova, računanje cijene klika/ulaza(CPC), cijene lead-a(CPL), računanje profita i potrošnje, praćenje prodaje i računanje approve rate-a, statistika ukupno i za danas. Sve to razvrstano po kampanjama i domenama. Praćenje se odvija preko pixel-a koji se generiše unikatno za svaku kampanju a API token za svaku pojedinačno domenu je zaslužan za sigurnost i obezbjeđuje tačnost i maksimalnu preciznost podataka. Također tu je i mogućnost više korisnika te njihova hierarhija. Izvještaj koji prema željenim parametrima prikazuje koja kampanja je ostvarila najbolji rezultat.'
}

export {bosnian};